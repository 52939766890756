/* eslint-disable react/display-name */
import { Label, Tooltip } from '@workos-inc/component-library';
import classnames from 'classnames';
import { TextInput } from 'components/form/text-input';
import { CopyIcon } from 'components/icons/copy';
import { ObfuscateIcon } from 'components/icons/obfuscate';
import React, { ChangeEvent, FocusEvent, forwardRef, useState } from 'react';
import { AlertCircle } from 'react-feather';
import { copyTextToClipboard } from 'utils/copy-text-to-clipboard';

export interface InputGroupProps {
  autoComplete?: string;
  autoFocus?: boolean;
  className?: string;
  error?: string;
  id: string;
  inline?: boolean;
  isCopyable?: boolean;
  isObfuscated?: boolean;
  label?: string;
  name: string;
  onBlur?: (event: FocusEvent) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onCopy?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  type?: 'text' | 'search' | 'email' | 'password';
  value?: string;
  size?: 'medium' | 'large';
}

export const InputGroup = forwardRef<
  HTMLInputElement,
  Readonly<InputGroupProps>
>(
  (
    {
      className,
      error,
      id,
      inline = false,
      isCopyable = false,
      isObfuscated = false,
      label,
      name,
      onBlur,
      onChange,
      onCopy,
      placeholder,
      autoComplete,
      autoFocus = false,
      readOnly = false,
      type,
      value,
      size,
    },
    ref,
  ) => {
    const [obfuscated, setIsObfuscated] = useState(isObfuscated);
    const [isCopied, setIsCopied] = useState<boolean>(false);

    const handleCopy = async () => {
      if (value) {
        await copyTextToClipboard(value);
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 3000);
        if (onCopy) {
          onCopy();
        }
      }
    };

    const overlays = [
      isCopyable && {
        key: 'copy-icon',
        content: (
          <Tooltip content={isCopied ? 'Copied' : 'Copy'}>
            <i className="appearance-none">
              <CopyIcon
                isCopied={isCopied}
                onClick={handleCopy}
                testId="copy-icon"
              />
            </i>
          </Tooltip>
        ),
      },
      isObfuscated && {
        key: 'obfuscate-icon',
        content: (
          <Tooltip content={obfuscated ? 'View' : 'Hide'}>
            <i className="appearance-none">
              <ObfuscateIcon
                isObfuscated={obfuscated}
                onClick={() => setIsObfuscated(!obfuscated)}
                testId="obfuscate-icon"
              />
            </i>
          </Tooltip>
        ),
      },
      error
        ? {
            key: 'error-icon',
            content: (
              <i className="appearance-none text-red">
                <AlertCircle size={16} />
              </i>
            ),
          }
        : null,
    ].filter(Boolean) as { key: string; content: JSX.Element }[];

    return (
      <fieldset
        className={classnames({ 'flex items-center': inline }, className)}
      >
        {label && (
          <Label
            className={classnames({ 'mb-2': !inline, 'w-44': inline })}
            htmlFor={id}
          >
            {label}
          </Label>
        )}
        <div className="relative w-full">
          <TextInput
            ref={ref}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            className="pr-9"
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            readOnly={readOnly}
            size={size}
            type={obfuscated ? 'password' : type}
            value={value}
          />
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            {overlays.map((icon, index) => (
              <div key={icon.key} className={index > 0 ? 'ml-2' : undefined}>
                {icon.content}
              </div>
            ))}
          </div>
        </div>
        {error && (
          <span
            className="text-xs font-medium text-red"
            data-testid={`${id}-error`}
          >
            {error}
          </span>
        )}
      </fieldset>
    );
  },
);
