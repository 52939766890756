import classnames from 'classnames';
import React, { ChangeEvent, FocusEvent, forwardRef, useMemo } from 'react';
import { noop } from 'utils/noop';

export interface TextInputProps {
  type?: 'text' | 'search' | 'email' | 'password';
  className?: string;
  id: string;
  name: string;
  placeholder?: string;
  autoFocus?: boolean;
  onBlur?: (event: FocusEvent) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  readOnly?: boolean;
  autoComplete?: string;
  size?: 'medium' | 'large';
}

export const TextInput = forwardRef<HTMLInputElement, Readonly<TextInputProps>>(
  (
    {
      type = 'text',
      id,
      name,
      className,
      placeholder,
      autoFocus = false,
      onBlur,
      onChange,
      value,
      readOnly = false,
      autoComplete = 'on',
      size = 'medium',
    },
    ref,
  ) => {
    const classNames = classnames(
      'border text-sm rounded w-full transition duration-200 ease-in-out',
      'bg-white border-gray-lightmode-200 text-gray-lightmode-400 placeholder-gray-lightmode-300',
      'dark:bg-gray-darkmode-50 dark:border-gray-darkmode-200 dark:text-gray-darkmode-400 dark:placeholder-gray-darkmode-300',
      {
        'hover:bg-white hover:border-gray-lightmode-300': !readOnly,
        'focus:bg-white focus:outline-none focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary': !readOnly,
        'bg-gray-lightmode-100 dark:bg-gray-darkmode-100 focus:outline-none text-gray-lightmode-300 dark:text-gray-darkmode-300': readOnly,
        'px-2 py-1.5 leading-6': size === 'medium',
        'px-2 py-3 leading-5': size === 'large',
      },
      className,
    );

    const handleChange = useMemo(() => (readOnly ? noop : onChange), [
      onChange,
      readOnly,
    ]);

    const handleFocus = (event: FocusEvent<HTMLInputElement>) => {
      if (readOnly) {
        event.currentTarget.select();
      }
    };

    return (
      <input
        ref={ref}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        className={classNames}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        readOnly={readOnly}
        type={type}
        value={value}
      />
    );
  },
);
