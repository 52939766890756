import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { FC } from 'react';

export interface ObfuscateIconProps {
  className?: string;
  isObfuscated: boolean;
  onClick?: () => void;
  testId: string;
}

export const ObfuscateIcon: FC<Readonly<ObfuscateIconProps>> = ({
  className,
  isObfuscated,
  onClick,
  testId,
}) => {
  const opacityVariant = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
      transition: {
        duration: 0.05,
        ease: 'easeInOut',
      },
    },
  };

  const pathVariant = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.1,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <svg
      className={classnames('cursor-pointer', className)}
      data-testid={testId}
      fill="none"
      height="16"
      onClick={onClick}
      stroke="currentColor"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 24 24"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        animate={isObfuscated ? 'visible' : 'hidden'}
        d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"
        variants={opacityVariant}
      />
      <motion.path
        animate={!isObfuscated ? 'visible' : 'hidden'}
        d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"
        variants={opacityVariant}
      />
      <motion.path
        animate={isObfuscated ? 'visible' : 'hidden'}
        d="M 1 1 23 23"
        variants={pathVariant}
      />
      <motion.path
        animate={!isObfuscated ? 'visible' : 'hidden'}
        d="M9,12a3,3 0 1,0 6,0a3,3 0 1,0 -6,0"
        variants={opacityVariant}
      />
    </svg>
  );
};
