import classnames from 'classnames';
import { motion } from 'framer-motion';
import React, { FC } from 'react';

export interface CopyIconProps {
  className?: string;
  isCopied: boolean;
  onClick?: () => void;
  testId: string;
}

export const CopyIcon: FC<Readonly<CopyIconProps>> = ({
  className,
  isCopied,
  onClick,
  testId,
}) => {
  const pathVariant = {
    hidden: {
      opacity: 0,
      pathLength: 0,
      transition: {
        duration: 0.25,
        ease: 'easeInOut',
      },
    },
    visible: {
      opacity: 1,
      pathLength: 1,
      transition: {
        duration: 0.25,
        ease: 'easeInOut',
      },
    },
  };

  return (
    <svg
      className={classnames('cursor-pointer', className)}
      data-testid={testId}
      fill="none"
      height="16"
      onClick={onClick}
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <motion.path
        animate={isCopied ? 'visible' : 'hidden'}
        d="M2.66667 8.00001L6 11.3333L12.3333 5.00001L13.2475 4.08582"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        variants={pathVariant}
      />

      <motion.path
        animate={!isCopied ? 'visible' : 'hidden'}
        d="M10.6667 2.66663H12C12.3536 2.66663 12.6928 2.8071 12.9428 3.05715C13.1929 3.3072 13.3333 3.64634 13.3333 3.99996V13.3333C13.3333 13.6869 13.1929 14.0261 12.9428 14.2761C12.6928 14.5262 12.3536 14.6666 12 14.6666H4C3.64638 14.6666 3.30724 14.5262 3.05719 14.2761C2.80714 14.0261 2.66666 13.6869 2.66666 13.3333V3.99996C2.66666 3.64634 2.80714 3.3072 3.05719 3.05715C3.30724 2.8071 3.64638 2.66663 4 2.66663H5.33333"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        variants={pathVariant}
      />
      <motion.path
        animate={!isCopied ? 'visible' : 'hidden'}
        d="M10 1.33337H6C5.63181 1.33337 5.33334 1.63185 5.33334 2.00004V3.33337C5.33334 3.70156 5.63181 4.00004 6 4.00004H10C10.3682 4.00004 10.6667 3.70156 10.6667 3.33337V2.00004C10.6667 1.63185 10.3682 1.33337 10 1.33337Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33"
        variants={pathVariant}
      />
    </svg>
  );
};
